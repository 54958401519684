import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { SpeakerIcon } from "vue-feather-icons";

export default function useTagsList() {
  // Use toast
  const toast = useToast();

  const refTagListTable = ref(null);
  const allTags = ref({
    data: [],
    key: 0,
  });
  // Table Handlers
  const tableColumns = [
    { key: "kod", label: VueI18n.t("code"), sortable: false },
    {
      key: "formulanutrientname",
      label: VueI18n.t("formulaNutrientName"),
      sortable: false,
    },
    {
      key: "tagnutrientname",
      label: VueI18n.t("tagNutrientName"),
      sortable: false,
    },
    { key: "edit", label: VueI18n.t("edit") },
    // { key: "delete", label: VueI18n.t("delete") },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refTagListTable.value
      ? refTagListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refTagListTable.value.refresh();
  };

  watch([currentPage, perPage, roleFilter, planFilter, statusFilter], () => {
    refetchData();
  });

  const debounce = (fn, delay) => {
    let timerId;
    return (...args) => {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };
  const searchQueryChanged = debounce(() => {
    refetchData();
  }, 400);

  const fetchTags = async (ctx, callback) => {
    var tag = await store
      .dispatch(
        "plantsModule/fetchNutrientTags",
        store.state.app.selectedPlantId
      )
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("tags") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    if (searchQuery.value != "")
      tag = tag.filter((x) =>
        x.formulanutrientname
          .toLowerCase()
          .startsWith(searchQuery.value.toLowerCase())
      );

    return tag;
  };

  const fetchTagVisibilities = async (ctx, callback) => {
    allTags.value.data = await store
      .dispatch(
        "plantsModule/fetchTagVisibilities",
        store.state.app.selectedPlantId
      )
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("tags") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    allTags.value.key += 1;
    return allTags.value;
  };

  const updateTagVisibility = async (ctx) => {
    await store
      .dispatch("plantsModule/updateTagVisibility", {
        plantid: store.state.app.selectedPlantId,
        values: ctx,
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("tags") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    refetchData();
  };

  const deleteTag = (ctx) => {
    store
      .dispatch("plantsModule/deleteTag", ctx)
      .then((response) => {
        refetchData();
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("tags") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("tags") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchTags,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTagListTable,
    deleteTag,
    allTags,
    refetchData,
    fetchTagVisibilities,
    updateTagVisibility,
    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    searchQueryChanged,
  };
}
