<template>
  <div>
    <tag-list-edit
      :key="selectedTag.id"
      :is-edit-tag-sidebar-active.sync="isEditTagSidebarActive"
      :tag.sync="selectedTag"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>adet</label> -->
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div
              class="d-flex align-items-center justify-content-between flex-wrap"
            >
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mw-33"
                :placeholder="$t('search')"
                @input="searchQueryChanged"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refTagListTable"
        class="position-relative"
        :items="fetchTags"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('noRecords')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Tag -->

        <template #cell(delete)="data">
          <b-link
            @click="
              deleteTag({
                plantid: $store.state.app.selectedPlantId,
                tagid: data.item.id,
              })
            "
          >
            <div class="d-flex">
              <feather-icon class="text-danger" icon="Trash2Icon" />
              <small class="d-none d-lg-inline align-middle ml-50 text-dark">{{
                $t("delete")
              }}</small>
            </div>
          </b-link>
        </template>

        <template #cell(edit)="data">
          <b-link @click="openEditSideBar(data)">
            <div class="d-flex">
              <feather-icon icon="EditIcon" />
              <small class="d-none d-lg-inline align-middle ml-50 text-dark">{{
                $t("edit")
              }}</small>
            </div>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
              {{ $t("entries") }} </span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTags"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useTagList from "./useTagList";
import TagListEdit from "./TagListEdit.vue";
import { getUserData } from "@/auth/utils";
import VueI18n from "@/libs/i18n";
import store from "@/store";

export default {
  components: {
    TagListEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,

    BTable,
    BLink,
    BPagination,
    // vSelect,
  },
  data() {
    return {
      // typeOptions: [],
      // genderOptions: [
      //   { label: "Dişi", value: "Dişi" },
      //   { label: "Erkek", value: "Erkek" },
      // ],
    };
  },
  beforeRouteEnter(to, from, next) {
    if (to.name == "nutrientTags") {
      to.meta.breadcrumb = [];

      to.meta.breadcrumb.push({
        text: VueI18n.t("nutrients"),
        active: true,
      });
      to.meta.breadcrumb.push({
        text: VueI18n.t("nutrientTags"),
        active: true,
      });
    }
    next();
  },
  setup({ emit }) {
    const isAddNewTagSidebarActive = ref(false);
    const isEditTagSidebarActive = ref(false);
    const selectedTag = ref({});
    const userData = getUserData();
    const openEditSideBar = (item) => {
      selectedTag.value = item;
      isEditTagSidebarActive.value = !isEditTagSidebarActive.value;
    };
    const {
      fetchTags,
      fetchTagVisibilities,
      tableColumns,
      perPage,
      currentPage,
      totalTags,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTagListTable,
      refetchData,
      allTags,
      updateTagVisibility,
      // UI
      perPageOptions,
      searchQueryChanged,

      // Extra Filters
      typeFilter,
      genderFilter,

      deleteTag,
    } = useTagList();

    return {
      // Sidebar
      isAddNewTagSidebarActive,
      isEditTagSidebarActive,
      openEditSideBar,
      allTags,
      selectedTag,
      fetchTags,
      fetchTagVisibilities,
      tableColumns,
      perPage,
      currentPage,
      totalTags,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTagListTable,
      refetchData,
      perPageOptions,
      updateTagVisibility,
      searchQueryChanged,

      // Filter
      avatarText,
      // UI
      userData,

      // Extra Filters
      typeFilter,
      genderFilter,
      deleteTag,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
